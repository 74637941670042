import React, { useState, useEffect, Fragment } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { useNavigate, useLocation } from "react-router-dom";
import { GoLock } from "react-icons/go";
import { imagesLinks } from "../../Constants/links";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import SDK from "../../config";
import Loader from "../Loader/loader";
import toast, { Toaster } from "react-hot-toast";
import handlePaymentSuccess from "./handlePaymentSuccess";
import TrackingScripts from "./trackingComponent";
import { handleSubmit } from "./eventHandler";

const CheckoutForm = ({ setCardBoxPosition }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [phone, setPhone] = useState(localStorage.getItem("phone") || "");
  const [zipcode, setZipcode] = useState(localStorage.getItem("zipcode") || "");
  const [cardName, setCardName] = useState("");
  const [showCardDetails, setShowCardDetails] = useState(false);
  const [cardBrand, setCardBrand] = useState("unknown");
  const [pageLoader, setPageLoader] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePayment, setCanMakePayment] = useState(false);
  const saveRegion = localStorage.getItem("region") || "us";
  const [regionText, setRegionText] = useState(SDK.REGION_CONFIG.us);

  const getCurrencySymbol = () => {
    const saveRegion = (localStorage.getItem("region") || "us").toLowerCase(); // Get region from localStorage and convert to lowercase
    switch (saveRegion) {
      case "us":
        return "$"; // USD
      case "uk":
        return "£"; // GBP
      case "eu":
        return "€"; // EUR
      default:
        return "$"; // Default to USD if no region matches
    }
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    localStorage.setItem("email", newEmail || email);
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
    localStorage.setItem("phone", value);
  };

  const handleZipcodeChange = (e) => {
    const newZipcode = e.target.value;
    setZipcode(newZipcode);
    localStorage.setItem("zipcode", newZipcode);
  };

  useEffect(() => {
    const useremail = params.get("email");
    if (useremail) {
      setEmail(useremail);
      localStorage.setItem("email", useremail);
    }
  }, [location]);

  useEffect(() => {
    if (!stripe) return;

    const paymentOf = "iOS";
    const saveRegion = localStorage.getItem("region") || "us";
    const { currency, price, countryCode } =
      SDK.REGION_CONFIG[saveRegion] || SDK.REGION_CONFIG["us"];
    console.log(
      `code:${countryCode}, region: ${saveRegion},currency: ${currency}, price: ${price}`
    );
    const paymentRequestInstance = stripe.paymentRequest({
      country: countryCode,
      currency: currency,
      total: {
        label: "Total",
        amount: price,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    // Check if the user can make a payment
    paymentRequestInstance
      .canMakePayment()
      .then((result) => {
        setCanMakePayment(result);
        setPaymentRequest(paymentRequestInstance);
      })
      .catch((error) => {
        console.error("Error checking PaymentRequest support:", error);
      });

    // Handle payment method events
    paymentRequestInstance.on("paymentmethod", async (event) => {
      const { paymentMethod, payerEmail } = event;

      try {
        const response = await fetch(
          `${SDK.BASE_URL}/api/create-payment-intent-apple-pay`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              payment_method_id: paymentMethod.id,
              email: payerEmail,
              paymentOf,
              regionCur: currency,
            }),
          }
        );

        const { client_secret: clientSecret } = await response.json();

        if (!clientSecret) {
          throw new Error("Client secret not returned from backend");
        }

        const { error, paymentIntent } = await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: paymentMethod.id,
          }
        );

        if (error) {
          console.error("Payment confirmation error:", error);
          event.complete("fail");
          return;
        }

        event.complete("success");

        // Retrieve user details from local storage
        const storeEmail = payerEmail;
        const storePhone = localStorage.getItem("phone");
        const storeZipcode = localStorage.getItem("zipcode");
        const methodType = "Apple Pay";
        const paymentWay = "iOS";
        await handlePaymentSuccess(
          paymentIntent,
          methodType,
          storeEmail,
          storeEmail,
          storePhone,
          storeZipcode,
          paymentWay
        );

        navigate(`/confirmation?email=${encodeURIComponent(storeEmail)}`);
        toast.success("Payment Successful");

        // Clear local storage
        localStorage.removeItem("email");
        localStorage.removeItem("phone");
        localStorage.removeItem("zipcode");
      } catch (error) {
        event.complete("fail");
        console.error("Unexpected error during payment processing:", error);
      }
    });
  }, [stripe, navigate]);

  useEffect(() => {
    if (elements) {
      const cardNumberElement = elements.getElement(CardNumberElement);
      if (cardNumberElement) {
        cardNumberElement.on("change", (event) => {
          setCardBrand(event.brand);
        });
      }
    }
  }, [elements]);

  useEffect(() => {
    const saveRegion = (localStorage.getItem("region") || "us").toLowerCase();
    const region = SDK.REGION_CONFIG[saveRegion];
    setRegionText(region);
  }, []);

  const cardStyle = {
    base: {
      fontSize: "13px",
      color: "#424770",
      "::placeholder": {
        color: "#aab7c4",
      },
      border: "1px solid #ced4da",
      padding: "8px",
      borderRadius: "4px",
    },
    invalid: {
      color: "#9e2146",
    },
  };

  const getCardBrandIcon = (brand) => {
    switch (brand) {
      case "visa":
        return imagesLinks.visaIcon;
      case "mastercard":
        return imagesLinks.mastercardIcon;
      case "amex":
        return imagesLinks.amexIcon;
      case "discover":
        return imagesLinks.discoverIcon;
      default:
        return imagesLinks.defaultIcon;
    }
  };

  return (
    <Fragment>
      <TrackingScripts />
      <Loader pageLoder={pageLoader} style={{ width: "100vw" }} />
      <div className="checkout-container">
        <Toaster />
      </div>

      <div className="checkout-container">
        <div className="scale-indicator-container">
          <div className="label1">
            <div className="dot1">
              <div className="dotfil1"></div>
            </div>
            <div className="label2">
              <p className="chp1">Early Access</p>
            </div>
          </div>
          <div className="line1"></div>
          <div className="label1">
            <div className="dot2 active">
              <div className="dotfil2"></div>
            </div>
            <div className="label2">
              <p className="chp2 active">Checkout</p>
            </div>
          </div>
          <div className="line2"></div>
          <div className="label1">
            <div className="dot3">
              <div className="dotfil3"></div>
            </div>
            <div className="label2">
              <p className="chp3">Confirmation</p>
            </div>
          </div>
        </div>
        <div className="formCheckout">
          <form
            onSubmit={(event) =>
              handleSubmit(
                event,
                stripe,
                elements,
                email,
                phone,
                zipcode,
                CardNumberElement,
                cardName,
                setPageLoader,
                navigate
              )
            }
            className="checkout-form"
          >
            <div className="personalDetail">
              <h6>1. Personal details</h6>
              <div className="input-row">
                <div className="emailField">
                  <label htmlFor="email">Email address</label>
                  <input
                    type="email"
                    autoComplete="none"
                    name="email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                  />
                </div>
                <div className="phoneField">
                  <label htmlFor="phone">Phone number</label>
                  <PhoneInput
                    country={"us"}
                    value={phone}
                    onChange={handlePhoneChange}
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                  />
                </div>
                <div className="zipcodeField">
                  <label htmlFor="zipcode">ZIP Code</label>
                  <input
                    type="text"
                    name="zipcode"
                    value={zipcode}
                    onChange={handleZipcodeChange}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="card-details">
              <div className="card_details_header">
                <div>
                  <h6>2. Payment details</h6>
                </div>
                <div className="d-flex align-content-center align-items-baseline">
                  <div className="mx-1">
                    <img src={imagesLinks.visaIcon} alt="Visa" />
                    <img src={imagesLinks.mastercardIcon} alt="Mastercard" />
                    <img src={imagesLinks.amexIcon} alt="Amex" />
                    <img src={imagesLinks.discoverIcon} alt="Discover" />
                  </div>
                  <div>
                    <p>and more</p>
                  </div>
                </div>
              </div>
              <div className=" d-flex align-items-center icons_text_class">
                <div className="lock_icon_class">
                  <GoLock />
                </div>
                <div>
                  <p>
                    We protect your payment information using encryption to
                    provide bank-level security.
                  </p>
                </div>
              </div>
              <div className="apple-payment">
                <div>
                  <div id="express-checkout-element">
                    {canMakePayment && paymentRequest ? (
                      <PaymentRequestButtonElement
                        options={{
                          paymentRequest: paymentRequest,
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="apple-or-card-payment">
                <p>OR</p>
              </div>
              {showCardDetails && (
                <div className="card-details-container">
                  <div className="card-details-field">
                    <label className="card-details-label">Name on Card</label>
                    <input
                      type="text"
                      value={cardName}
                      onChange={(e) => setCardName(e.target.value)}
                      required
                      className="card-holder-name"
                    />
                  </div>
                  <div className="card-details-field">
                    <label className="card-name-label">Card Number</label>
                    <div className="card-number-container">
                      <img
                        src={getCardBrandIcon(cardBrand)}
                        alt="Card Brand"
                        className="card-brand-icon"
                      />
                      <div className="card-element">
                        <CardNumberElement options={{ style: cardStyle }} />
                      </div>
                    </div>
                  </div>
                  <div className="card-details-flex">
                    <div className="card-details-field small">
                      <label className="card-details-label">Expiry</label>
                      <div className="card-details-element-container">
                        <CardExpiryElement options={{ style: cardStyle }} />
                      </div>
                    </div>
                    <div className="card-details-field small">
                      <label className="card-details-label">CVC</label>
                      <div className="card-details-element-container">
                        <CardCvcElement options={{ style: cardStyle }} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="card-payment">
                <button
                  type="submit"
                  className="pay-button"
                  onClick={() => {
                    setShowCardDetails(true);
                    setCardBoxPosition(true);
                  }}
                >
                  {showCardDetails
                    ? `Get lifetime acess for ${getCurrencySymbol()}${
                        SDK.FB_PRICE
                      }`
                    : "Pay with credit Card"}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="bottom_div">
          <p className="p1">Disclaimer</p>
          <p className="p2">
            Offer for iOS users only. {regionText.text ?? "USD"} 
          </p>
          <p className="p2">
            Due to iOS limitations, we cannot fully replace the iOS home screen,
            but have created widgets to make your home screen as minimal as
            possible. Unfortunately, it can be quite easy to bypass our app, so
            it'll require a little bit more discipline from you!
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default CheckoutForm;
