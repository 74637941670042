import LauncherPage from "../pages/launcherPage";
import ReservationPage from "../pages/reservationPage";
import PrivacyPolicies from "../pages/privacyPolicies";
import CheckOutPage from "../pages/checkOutPage";
import ConfirmationPageComponent from "../pages/confirmationPage";
import TermsOfServicesPage from "../pages/termsOfServicesPage";
import {
  Route,
  Navigate,
  Routes,
  useLocation,
  Router,
  useNavigate,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";

const DynamicGoogleTag = () => {
  const location = useLocation();

  return (
    <Helmet>
      {/* Add the async Google tag script */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-RS8S9736X2"
      ></script>
      {/* Add the inline Google tag configuration */}
      <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-RS8S9736X2', { 'page_path': '${location.pathname}' });
          `}
      </script>
    </Helmet>
  );
};
const AppRoutes = () => {

  return (
    <>
      <DynamicGoogleTag />
      <Routes>
        <Route path="/:region?" element={<LauncherPage />} />
        <Route path="/:region?/reservation" element={<ReservationPage />} />
        <Route path="/launcher-privacy-policy" element={<PrivacyPolicies />} />
        <Route path="/terms-of-service" element={<TermsOfServicesPage />} />
        <Route path="/:region?/payment-checkout" element={<CheckOutPage />} />
        <Route path="/confirmation" element={<ConfirmationPageComponent />} />
        <Route path="/*" element={<Navigate to="/" replace />} />

      </Routes>
    </>
  );
};

export default AppRoutes;
