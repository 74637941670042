import React, { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { imagesLinks } from "../Constants/links";
import { FooterSection } from "../Components/LauncherPageComponent/launcherPageComponent";
import SDK from "../config";
import "../Components/styles/launcherPageComponent.css";
import TrackingScripts from "../Components/checkoutPageComponent/trackingComponent";
import postVisitorsData from "../Components/web/visitors";

const ReservationPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get("email");
  const navigate = useNavigate();
  const eventId = `event-${new Date().getTime()}`;
  const saveRegion = localStorage.getItem("region") || "us";

  const [currRegion, setCurrRegion] = useState("");

  useEffect(() => {
    const region = localStorage.getItem("region");
    if (region === "uk" || region === "eu") {
      setCurrRegion(`${region}`);
    } else {
      setCurrRegion("");
    }
  }, []);
  const [regionText, setRegionText] = useState(SDK.REGION_CONFIG.us);

  const clickCountMeIn = () => {
    let pathToNav =
      currRegion !== ""
        ? `/${currRegion}/payment-checkout?email=${encodeURIComponent(email)}`
        : `/payment-checkout?email=${encodeURIComponent(email)}`;
    navigate(pathToNav);

    if (window.fbq) {
      window.fbq("track", "AddToCart", { event_id: eventId });
    }

    if (window.ttq) {
      window.ttq.track("AddToCart", { event_id: eventId });
    }

    const lead = true;
    const addtocart = true;
    const purchase = false;
    const amount = null;
    const tagsString = "Android Website";
    postVisitorsData({
      email,
      lead,
      addtocart,
      purchase,
      amount,
      tags: tagsString,
    });
  };

  // Check if the current route is '/reservation'
  const isReservationRoute =
    location.pathname === "/reservation" ||
    location.pathname === "/eu/reservation" ||
    location.pathname === "/uk/reservation";

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 829);

  useEffect(() => {
    const saveRegion = (localStorage.getItem("region") || "us").toLowerCase();
    const region = SDK.REGION_CONFIG[saveRegion];
    setRegionText(region);
    console.log("region Text", regionText);

    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 829);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Fragment>
      <TrackingScripts />
      <div style={{ overflow: "hidden" }}>
        <div className="reservation_page">
          <div className="text_part">
            <div className="reservation_logo">
              <div>
                <img
                  src={imagesLinks.logo}
                  className="navLogo"
                  style={{
                    paintOrder: "stroke fill",
                    fill: "black",
                    WebkitTextStroke: "12px black",
                  }}
                  alt=""
                />
              </div>
            </div>

            <div className="text_box">
              <p className="pa1">
                Welcome! Get 80% off & lifetime access for{" "}
                {regionText.currSymbol ?? "$"}
                {SDK.FB_PRICE} {(regionText.currency ?? "USD").toUpperCase()}
              </p>
              {/*<p className="pa3">
                Offer ends as soon as Apple approves our full launch, ETA within
                a month. We plan on charging new users $49 yearly once we fully
                launch.
              </p>
              <p className="pa3">
                Some early access spots may open on a first come, first serve
                basis.
              </p>*/}
              <p className="pa3">
                Offer ends as soon we launch in next few weeks. New users (not
                you!) will be charged $49 yearly.
              </p>
              <p className="pa3">
                That's 1/10 the price of a dumbphone, without sacrificing the
                utility of your smartphone or needlessly polluting the
                environment.
              </p>
              {/* <p className="pa3">
                Why do we bother with such a steep discount? It helps support
                our early development, and we like to reward early birds.
              </p> */}
              <button onClick={clickCountMeIn}>Preorder lifetime access</button>
            </div>
          </div>
          <div
            className={`cards_part ${
              isReservationRoute ? "reservation-specific" : ""
            }`}
          >
            <div className="img_text_box">
              <div className="card-img-text-box">
                <div className="card-team-img">
                  <img src={imagesLinks.minimisTeamPhoto} alt="" />
                </div>
              </div>
              <div
                className={`reservation_crad_box ${
                  isReservationRoute ? "reservation_page_box" : ""
                }`}
              >
                <div className="card1">
                  <div className="card_icon">
                    <img src={imagesLinks.c1} alt="" />
                  </div>
                  <div className="card_text">
                    <p className="card_p1">
                      Lock in a massive lifetime discount
                    </p>
                    <p className="card_p2">
                      Early bird gets the worm! Offer ends when we launch on the
                      app store.
                    </p>
                  </div>
                </div>
                <div className="card1">
                  <div className="card_icon">
                    <img src={imagesLinks.c2} alt="" />
                  </div>
                  <div className="card_text">
                    <p className="card_p1">Full refund guarantee</p>
                    <p className="card_p2">
                      Not a fan? No problem. Email us for a full refund before
                      we launch on the app store.
                    </p>
                  </div>
                </div>
                <div className="card1 card-bottom">
                  <div className="card_icon">
                    <img src={imagesLinks.c3} alt="" />
                  </div>
                  <div className="card_text">
                    <p className="card_p1">Payment secured by Stripe</p>
                    <p className="card_p2">
                      The same payment processor Apple, Amazon, Etsy, Walmart &
                      Target use. No credit card details seen or stored by us!
                    </p>
                  </div>
                </div>
                {/* Move the footer_section here if screen width is less than 830px */}
                {isMobileView && (
                  <div className="footer_section">
                    <FooterSection />
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* Render the footer_section normally if screen width is greater than 830px */}
        </div>
        {!isMobileView && (
          <div className="footer_section">
            <FooterSection />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default ReservationPage;
