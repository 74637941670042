import React, { Fragment, useState, useEffect } from "react";
import logo from "../assests/images/MINIMIS.svg";
import "../Components/styles/launcherPage.css";
import { imagesLinks } from "../Constants/links";
import SDK from "../config";
import axios from "axios";
import {
  AddictiveAppCards,
  FooterSection,
  NewportSection,
  TimeLimitCards,
  WelcomeScreenCards,
} from "../Components/LauncherPageComponent/launcherPageComponent";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import TrackingScripts from "../Components/checkoutPageComponent/trackingComponent";
import postVisitorsData from "../Components/web/visitors";

const LauncherPage = () => {
  const { region } = useParams();
  const [currRegion, setCurrRegion] = useState("");

  useEffect(() => {
    if (region === "uk" || region === "eu") {
      localStorage.setItem("region", region);
      setCurrRegion(`${region}`);
    } else {
      localStorage.setItem("region", "us");
      setCurrRegion("");
    }
    console.log("Region:", region);
  }, [region]);

  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const eventId = `event-${new Date().getTime()}`;

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const earlyAccessClick = (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      toast.error("Please enter an email.");
      return;
    } else if (!emailRegex.test(email)) {
      toast.error("Email not valid");
      return;
    }

    const tagsString = "iOS Website";
    const queryParams = new URLSearchParams({
      email_address: email,
      status: "subscribed",
      tags: tagsString,
      AddToCart: "1",
    });

    const queryString = queryParams.toString();
    axios
      .post(`${SDK.BASE_URL}/api/post_email_mailchimp?${queryString}`)
      .then((response) => {
        // console.log("Email saved:", response);
      })
      .catch((error) => {
        console.error("Error saving email:", error);
      });

    if (window.fbq) {
      window.fbq("track", "Lead", { event_id: eventId });
    }

    if (window.ttq) {
      window.ttq.track("Pageview", { event_id: eventId });
    }
    const lead = true;
    const addtocart = false;
    const purchase = false;
    const amount = null;

    postVisitorsData({
      email,
      lead,
      addtocart,
      purchase,
      amount,
      tags: tagsString,
    });

    let pathToNav =
      currRegion !== ""
        ? `/${currRegion}/reservation?email=${encodeURIComponent(email)}`
        : `/reservation?email=${encodeURIComponent(email)}`;
    navigate(pathToNav);
  };

  const handlePlayStoreClick = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=life.minimis.launcher&hl=en",
      "_blank"
    );
  };

  return (
    <Fragment>
      <Toaster />
      <TrackingScripts />
      <div className="overflow-x-hidden">
        <div className="minimis_launcher">
          <div className="launcher_navbar d-flex justify-content-between">
            <div>
              <img
                src={logo}
                className="navLogo"
                style={{
                  paintOrder: "stroke fill",
                  fill: "black",
                  "-webkit-text-stroke": "12px black",
                  height: "28px",
                  width: "179px",
                }}
                alt="Logo"
              />
            </div>
            {/** 
            <div>
              <img src={menu} style={{ height: "20px", width: "24px" }} alt="Menu" />
            </div>
             */}
          </div>

          <div className="phone_mockup_section">
            <section className="section1">
              <div>
                <p className="p1">Minimal Distractions. Maximal Life.</p>
                <p className="p2">
                  Minimis Launcher brings your phone back to basics. You control
                  your phone. Not the other way around.
                </p>
              </div>
              <div className="mt-5 emailDiv w-100">
                <form onSubmit={earlyAccessClick} className="pt-4 d-flex">
                  <div>
                    <input
                      type="text"
                      placeholder="Your email..."
                      value={email}
                      onChange={handleEmailChange}
                    />
                  </div>
                  <button type="submit">Get lifetime access</button>
                </form>
              </div>
            </section>
            <section className="section2">
              <div>
                <img src={imagesLinks.iphoneMockup} alt="iPhone Mockup" />
              </div>
            </section>
          </div>
        </div>

        <div className="addictiveApp_cards">
          <AddictiveAppCards />
        </div>

        <div className="call_newport_section">
          <NewportSection />
        </div>

        <div className="timeLimit_cards">
          <TimeLimitCards />
        </div>

        <div className="welcome_screen_cards">
          <WelcomeScreenCards />
        </div>

        <div className="early_access_cards">
          <div>
            <p className="p1">Get Lifetime Access</p>
            <p className="p2">
              Get in early and help shape the launcher you would use!
            </p>
          </div>
          <div className="pt-4 d-flex">
            <div>
              <input
                type="text"
                placeholder="Your email..."
                value={email}
                onChange={handleEmailChange}
              />
            </div>
            <button onClick={earlyAccessClick}>Get lifetime access</button>
          </div>
        </div>
        <div className="android-main-container">
          <div className="android-google-container">
            <div className="android-get-google">
              <div className="android-get-google-text">
                <p>Android User?</p>
                <div className="android-get-google-launched">
                  <p>We've launched already!</p>
                </div>
              </div>
              <div
                className="android-get-google-image"
                style={{ cursor: "pointer" }}
              >
                <img
                  src={imagesLinks.googlePlaystoreImage}
                  onClick={handlePlayStoreClick}
                  alt=""
                />
              </div>
            </div>
            <div className="android-google-mobile">
              <img src={imagesLinks.androidMobileLauncherImage} alt="" />
            </div>
          </div>
          <div className="android-download-review">
            <div className="android-download-playstore">
              <div className="android-download-playstore-review">
                <img src={imagesLinks.minimisDownloadAppImage} alt="" />
              </div>
              <div>
                <img src={imagesLinks.ratingReviewImage} alt="" />
              </div>
            </div>
            <div className="android-download-users-review">
              <div className="android-download-app-rating">
                <div className="android-play-store-icon">
                  <img
                    src={imagesLinks.playStoreIcon}
                    alt=""
                    style={{ width: "150px" }}
                  />
                </div>
                <div className="android-download-rating">
                  <div>
                    <img
                      src={imagesLinks.ratingStarImage}
                      alt=""
                      style={{ width: "96px", height: "16px" }}
                    />
                  </div>
                  <div className="android-download-rating-text">
                    <p>-Constantijn</p>
                  </div>
                </div>
              </div>
              <div className="android-downlod-rating-review-center"></div>
              <center>
                <div className="android-download-app-review">
                  <span className="android-download-app-review-light-text">
                    Minimis is the only minimalist launcher that has transformed
                    my phone habits.
                  </span>
                  <span className="android-download-app-review-bold-text">
                    <>
                      {" "}
                      For the first time, I feel like I control my phone, not
                      the other way around.{" "}
                    </>
                  </span>
                  <span className="android-download-app-review-light-text">
                    The added friction layers between you and those "bad"
                    dopamine-stealing apps work brilliantly. You even feel
                    guilty thinking about opening one!
                  </span>
                  <span className="android-download-app-review-bold-text">
                    <> Minimis has changed my life. </>
                  </span>
                  <span className="android-download-app-review-light-text">
                    My focus strengthens daily, my baseline anxiety has dropped,
                    and I get more done. My brain feels better overall, like in
                    the "good old days" of the 90s, I feel calm. :)
                  </span>
                </div>
              </center>
            </div>
          </div>
        </div>

        <div className="footer_section">
          <FooterSection />
        </div>
      </div>
    </Fragment>
  );
};

export default LauncherPage;
